<template>
  <div>
    <div class="flix-form-group">
      <h2 class="flix-html-h2">{{ $t('message.calendarUse') }}</h2>
    </div>
    <div class="flix-list-group flix-text-info">
      <flixIcon :id="'info-sign'" /> {{ $t('message.pleaseUseBookingForm') }}
    </div>
    <div class="flix-list-group">
      <a href="#" @click.prevent="setData(option.value)" class="flix-list-group-item flix-html-a" :class="{'flix-active': option.value === data.type}" v-for="(option, index) in options" :key="index">
        <flixIcon class="flix-icon" :id="option.icon" />
        <h3 class="flix-html-h3">{{ option.title }}</h3>
        {{ option.description }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { data: Object, callback: Function },
  data () {
    return {
      options: this.getOptions()
    }
  },
  methods: {
    getOptions () {
      var variables = this.$getUserVariables()
      var options = []
      options[0] = {
        title: this.$t('message.dateTitle'),
        description: this.$t('message.dateTitlePlaceholder'),
        value: 'datepicker',
        icon: 'shop'
      }
      options[1] = {
        title: this.$t('message.eventTitle'),
        description: this.$t('message.eventTitlePlaceholder'),
        value: 'eventpicker',
        icon: 'pushpin'
      }

      if (typeof variables.user === 'object' && typeof variables.user.md5_id === 'string') {
        return options
      }
      options[2] = {
        title: this.$t('message.serviceTitle'),
        description: this.$t('message.serviceTitlePlaceholder'),
        value: 'servicepicker',
        icon: 'blacksmith'
      }

      return options
    },
    setData (type) {
      return this.callback({ type: type })
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-active
    .flix-html-h3
      color: white

  .flix-html-h3
    margin-top: 0
    font-weight: bold

  .flix-icon
    font-size: 40pt
    float: left
    margin-right: 15px
    padding: 4px 0
</style>
